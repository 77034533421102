<!-- 办理完结内容 -->
<template>
  <div class="">
    <ax-form ref="form1" :formBuilder="formBuilder1">
    </ax-form>
    <div class="bottom">
      <time-line :steps="steps"></time-line>
    </div>
  </div>
</template>

<script>
import TimeLine from "./timeLine.vue";
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
const formList1 = [
  {
    label: "事件打分",
    type: "",
    model: "dispatchedPersonnel",
    options: { placeholder: "请输入", maxLength: 200 },
    col: { span: 24 },
    // rules: [{ required: true, message: "请选择派遣人员" }],
  },
  {
    label: "结案意见",
    type: "textarea",
    model: "dispatchedInstructions",
    options: { placeholder: "请输入", minRows: 3,  maxLength: 200 },
    col: { span: 24 },
    rules: [{ required: true, message: "请输入结案意见" }],
  },
];
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { TimeLine },
  props: ["status1", "steps"],
  data() {
    //这里存放数据
    return {
      formBuilder1: this.$common.initGridFormData(
        formList1,
        { layout: "horizontal", labelWidth: 120,disabled: true },
        { col: { span: 8 } }
      ),
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
/deep/.ant-form {
  border: 1px solid #e2e8f1;
  padding: 2%;
  padding-bottom: 0;
}
.bottom {
  margin-top: 1%;
}
.btn-group {
  margin-top: 2%;
}
</style>